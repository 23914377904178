<script setup lang="ts">
export interface iBaseModalHeaderProps {
  showClose?: boolean
  title?: string | null
}

withDefaults(defineProps<iBaseModalHeaderProps>(), {
  showClose: true,
  title: '',
})

const emit = defineEmits(['close'])
</script>

<template>
  <div class="flex items-center border-b border-gray-100 px-4 py-5 sm:px-6">
    <div class="flex-1 text-2xl font-bold leading-tight text-gray-900">
      {{ title }}
    </div>
    <div>
      <BaseButton
        v-if="showClose"
        size="sm"
        class="border-none shadow-none hover:bg-gray-100"
        variant="transparent"
        @click="emit('close')"
      >
        <BaseIcon
          name="heroicons:x-mark-20-solid"
          class="h-5 w-5 text-gray-500"
        />
      </BaseButton>
    </div>
  </div>
</template>
