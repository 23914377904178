<script setup lang="ts">
import { computed } from 'vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

export interface iBaseModalProps {
  backgroundClose?: boolean
  bodyPadding?: boolean
  show: boolean
  showClose?: boolean
  initialValues?: any
  fullScreen?: boolean
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | 'full'
    | 'min'
    | 'max'
  title?: string | null
}

const props = withDefaults(defineProps<iBaseModalProps>(), {
  bodyPadding: true,
  backgroundClose: true,
  initialValues: () => {},
  fullScreen: false,
  title: '',
  show: false,
  showClose: true,
  size: 'sm',
})

const emit = defineEmits(['close'])

const sizeClass = computed(() => {
  const sizeChart = {
    xs: 'max-w-xs sm:w-full',
    sm: 'max-w-sm sm:w-full',
    md: 'max-w-md sm:w-full',
    lg: 'max-w-lg sm:w-full',
    xl: 'max-w-xl sm:w-full',
    '2xl': 'sm:max-w-2xl sm:w-full',
    '3xl': 'max-w-3xl sm:w-full',
    '4xl': 'max-w-4xl sm:w-full',
    '5xl': 'max-w-5xl sm:w-full',
    '6xl': 'max-w-6xl sm:w-full',
    '7xl': 'max-w-7xl sm:w-full',
    full: 'max-w-full sm:w-full',
    min: 'max-w-min',
    max: 'max-w-max',
  }

  return sizeChart[props.size] || ''
})

const paddingClass = computed(() => {
  if (!props.bodyPadding) {
    return ''
  }

  return 'px-4 pt-5 pb-4 sm:p-6'
})

const handleBackgroundClick = () => {
  if (props.backgroundClose) {
    emit('close')
  }
}

const handleClose = () => {
  emit('close')
}
</script>

<template>
  <TransitionRoot appear as="template" :show="show">
    <Dialog
      as="div"
      class="base-modal-class fixed inset-0 z-40 overflow-y-auto"
      data-cy="base-modal-dialog"
      @close="handleBackgroundClick"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0"
          :class="[props.fullScreen ? '' : 'p-4']"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogPanel
              class="transform overflow-hidden bg-white text-left transition-all sm:w-full"
              :class="[
                props.fullScreen
                  ? ''
                  : `rounded-lg shadow-xl relative ${sizeClass}`,
              ]"
            >
              <slot name="header">
                <base-modal-header
                  v-if="title"
                  :title="title"
                  :show-close="showClose"
                  @close="emit('close')"
                />
              </slot>
              <div :class="paddingClass">
                <slot></slot>
              </div>
              <slot name="footer"></slot>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
